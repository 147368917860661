import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Checkbox, Popover, Space, Table, Flex, Typography } from "antd"
import ProcessStatus from "../../ProcessStatus"
import { useOrder } from "../../../shared/hooks/UseOrder"
import { observer } from "mobx-react"
import { useStore } from "../../../../stores"
import { Loader } from "../../../shared"
import moment from "moment"
import { rearrangeName } from "../../OTAScreenHeader"

const columns = () => {
	return [
		{
			title: "Process Name",
			dataIndex: "processName",
			key: "processName",
		},

		{
			title: "Customers",
			dataIndex: "customers",
			key: "customers",
			size: "small",
			width: "150px",
			ellipsis: true,
			textWrap: "word-break",
			render: (_, { customers }) => {
				const content = customers?.map(({ name: customer }, idx) =>
					customers?.length !== idx + 1 ? `${customer}, ` : customer,
				)

				const row = (
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							alignItems: "flex-start",
							width: "400px",
							gap: "10px",
						}}
					>
						{customers?.map(({ name: customer }) => (
							<Checkbox style={{ width: "45%" }} key={customer} checked disabled>
								{customer}
							</Checkbox>
						))}
					</div>
				)

				return (
					content && (
						<Popover style={{ width: "500px" }} content={row} title="">
							<div
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									width: "150px",
								}}
							>
								{content}
							</div>
						</Popover>
					)
				)
			},
		},

		{
			title: "Started On Date",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: true,
			// render: (_, { createdAt }) => <TimeDisplay date={createdAt} />,
			render: (_, { createdAt }) => moment(createdAt).format("MM.DD.YYYY HH:mm"),
		},

		{
			title: "Created By",
			dataIndex: "author",
			key: "author",
			minWidth: "200px",
			render: (_, { author }) => rearrangeName(author || ""),
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: "150px",

			sorter: (a, b) => {
				const order = ["Active", "Stopped", "None"]
				return order.indexOf(a.status) - order.indexOf(b.status)
			},
			render: (_, { status }) => status && <ProcessStatus status={status} />,
		},
	]
}

const VersionProcessesTable = observer(({ versionId }) => {
	const { dataStore, versionDetailsStore } = useStore()

	//Rename data for exact OTA
	const [page, setPage] = useState(0)
	const [order, handleOrder] = useOrder()
	const [loading, setLoading] = useState()
	const [pageSize, setPageSize] = useState(5)
	const processes = useMemo(() => {
		return dataStore.processes
	}, [dataStore.processes])

	const details = useMemo(() => versionDetailsStore.versionDetails, [versionDetailsStore.versionDetails])

	const fetchVersionDetails = async (id) => {
		await versionDetailsStore.getDetails(id)
	}

	useEffect(() => {
		fetchVersionDetails(versionId)

		return () => versionDetailsStore.clearDetails()
	}, [versionId])

	const handlePagination = (page, pageSize) => {
		setPage(page - 1)
		setPageSize(pageSize)
	}

	const fetchProcesses = async (page, limit, order) => {
		setLoading(true)
		try {
			dataStore.setProcessFilters({
				offset: page * limit,
				limit,
				...order,
				filter: {
					where: {
						versionId: versionId,
					},
				},
			})

			await dataStore.getProcesses()
		} catch (err) {
			console.log("err", err)
		}

		setLoading(false)
	}

	useEffect(() => {
		fetchProcesses(page, pageSize, order)
	}, [page, pageSize, order.sort, order.order, versionId])

	const memoColumns = useMemo(() => {
		return columns()
	}, [])

	if (versionDetailsStore.inProgress) return <Loader />

	return (
		<Flex justify="flex-start" align="flex-start" gap={"middle"} style={{ width: "100%" }} vertical>
			<Typography.Title level={4}>{details?.name || "Version"}</Typography.Title>
			<Table
				onRow={(row) => ({})}
				style={{ width: "100%" }}
				loading={loading}
				scroll={{ x: true }}
				columns={memoColumns}
				dataSource={processes?.rows}
				rowKey={(item) => item.id}
				onChange={(_a, _b, sorting) =>
					handleOrder({
						order: sorting.order,
						sort: sorting.columnKey === "status" ? "statusPriority" : sorting.columnKey,
					})
				}
				className="process-table"
				pagination={{
					className: "pagination",
					showSizeChanger: false,
					position: ["bottomRight"],
					total: processes?.total,
					pageSize: pageSize,
					current: page + 1,
					onChange: (page, pageSize) => handlePagination(page, pageSize),
				}}
			/>
		</Flex>
	)
})

VersionProcessesTable.propTypes = { versionId: PropTypes.string }

export default VersionProcessesTable
