import React, { useEffect, useMemo, useState } from "react"
import { Checkbox, Popover, Space, Table } from "antd"
import { CaretRightOutlined, DeleteOutlined, EditOutlined, PauseCircleOutlined } from "@ant-design/icons"
import TooltipIconButton from "../shared/TooltipIconButton"
import { WarningModal } from "../shared/modals"
import { API } from "../../App"
import ProcessStatus, { OTAProcessStatus } from "./ProcessStatus"
import EditProcessModal from "../shared/modals/processes/EditProcessModal"
import { useOrder } from "../shared/hooks/UseOrder"
import { observer } from "mobx-react"
import { useStore } from "../../stores"
import moment from "moment"
import { rearrangeName } from "./OTAScreenHeader"

const columns = ({ onStopClick, onDeleteClick, onStartClick, onEditClick }) => {
	return [
		{
			title: "Process Name",
			dataIndex: "processName",
			key: "processName",
		},
		{
			title: "Version Name",
			dataIndex: "versionName",
			key: "versionName",
		},

		{
			title: "Customers",
			dataIndex: "customers",
			key: "customers",
			size: "small",
			width: "150px",
			ellipsis: true,
			textWrap: "word-break",
			render: (_, { customers }) => {
				const content = customers?.map(({ name: customer }, idx) =>
					customers?.length !== idx + 1 ? `${customer}, ` : customer,
				)

				const row = (
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							alignItems: "flex-start",
							width: "400px",
							gap: "10px",
						}}
					>
						{customers?.map(({ name: customer }) => (
							<Checkbox style={{ width: "45%" }} key={customer} checked disabled>
								{customer}
							</Checkbox>
						))}
					</div>
				)

				return (
					content && (
						<Popover style={{ width: "500px" }} content={row} title="">
							<div
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									width: "150px",
								}}
							>
								{content}
							</div>
						</Popover>
					)
				)
			},
		},

		{
			title: "Started On Date",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: true,
			// render: (_, { createdAt }) => <TimeDisplay date={createdAt} />,
			render: (_, { createdAt }) => moment(createdAt).format("MM.DD.YYYY HH:mm"),
		},

		{
			title: "Created By",
			dataIndex: "author",
			key: "author",
			minWidth: "200px",
			render: (_, { author }) => rearrangeName(author || ""),
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: "150px",

			sorter: (a, b) => {
				const order = ["Active", "Stopped", "None"]
				return order.indexOf(a.status) - order.indexOf(b.status)
			},
			render: (_, { status }) => status && <ProcessStatus status={status} />,
		},
		{
			title: "Action",
			key: "action",
			render: (_, row) => {
				const { status } = row

				return (
					<Space size="middle">
						{[OTAProcessStatus.QUEUED, OTAProcessStatus.READY, OTAProcessStatus.ABORTED].includes(status) && (
							<TooltipIconButton onClick={() => onStartClick(row.id)} icon={<CaretRightOutlined />} text="Start" />
						)}

						{[OTAProcessStatus.QUEUED, OTAProcessStatus.READY].includes(status) && (
							<TooltipIconButton onClick={() => onEditClick(row)} icon={<EditOutlined />} text="Edit" />
						)}

						{status === OTAProcessStatus.IN_PROGRESS && (
							<TooltipIconButton
								onClick={() => onStopClick(row.id)}
								icon={<PauseCircleOutlined color="orange" />}
								text="Stop"
							/>
						)}

						{[
							OTAProcessStatus.QUEUED,
							OTAProcessStatus.READY,
							OTAProcessStatus.ABORTED,
							OTAProcessStatus.COMPLETED,
							OTAProcessStatus.DISABLED,
						].includes(status) && (
							<TooltipIconButton
								onClick={() => onDeleteClick(row.id)}
								icon={<DeleteOutlined color="red" />}
								text="Delete"
							/>
						)}
					</Space>
				)
			},
		},
	]
}

const ProcessTable = observer(() => {
	const { dataStore, processDetailsStore } = useStore()

	const [openWarningModal, setOpenWarningModal] = useState()
	const [warningModalProps, setWarningModalProps] = useState()

	const [openEditProcessModal, setOpenEditProcessModal] = useState()
	const [editProcessModalProps, setEditProcessModalProps] = useState()

	//Rename data for exact OTA
	const [page, setPage] = useState(0)
	const [order, handleOrder] = useOrder()
	const [loading, setLoading] = useState()
	const [pageSize, setPageSize] = useState(5)
	const processes = useMemo(() => {
		if (dataStore?.processes?.rows) {
			if (dataStore?.processes?.rows?.length > 0) {
				const firstProcess = dataStore?.processes?.rows[0]
				dataStore.setSelectedProcessId(firstProcess ? firstProcess?.id : null)
			}
		}

		return dataStore.processes
	}, [dataStore.processes])

	const handlePagination = (page, pageSize) => {
		setPage(page - 1)
		setPageSize(pageSize)
	}

	const handleSetProcessId = (row) => {
		// if (row.status === OTAProcessStatus.DISABLED) return null
		dataStore.setSelectedProcessId(row.id)
	}

	const fetchProcesses = async (page, limit, order) => {
		setLoading(true)
		try {
			dataStore.setProcessFilters({ offset: page * limit, limit, ...order })

			await dataStore.getProcesses()

			if (dataStore?.processes?.rows) {
				if (dataStore?.processes?.rows?.length > 0) {
					const firstProcess = dataStore?.processes?.rows[0]
					dataStore.setSelectedProcessId(firstProcess ? firstProcess?.id : null)
				}
			}
		} catch (err) {
			console.log("err", err)
		}

		setLoading(false)
	}

	useEffect(() => {
		fetchProcesses(page, pageSize, order)
	}, [page, pageSize, order.sort, order.order])

	const handleWarningModalClose = async () => {
		setOpenWarningModal()
	}

	const handleWarningModalOnStopClose = async (id) => {
		setOpenWarningModal()
		await API.stopProcess(id)
		await dataStore.getProcesses()
		await processDetailsStore.getDetails(id)
	}

	const handleWarningModalOnStartClose = async (id) => {
		setOpenWarningModal()
		await API.startProcess(id)
		await dataStore.getProcesses()
		await processDetailsStore.getDetails(id)
	}

	const handleWarningModalOnDeleteClose = async (id) => {
		setOpenWarningModal()
		await API.deleteProcess(id)
		await dataStore.getProcesses()
		await processDetailsStore.getDetails(id)
	}

	const handleEditProcessModalClose = () => setOpenEditProcessModal()

	const memoColumns = useMemo(() => {
		const onStopClick = (id) => {
			setWarningModalProps({
				cancelButtonText: "No",
				submitButtonText: "Yes",
				onSubmit: () => handleWarningModalOnStopClose(id),
				title: "Are you sure what to stop the process?",
			})

			setOpenWarningModal(true)
		}

		const onDeleteClick = (id) => {
			setWarningModalProps({
				cancelButtonText: "No",
				submitButtonText: "Yes",
				onSubmit: () => handleWarningModalOnDeleteClose(id),
				title: "Are you sure what to delete the process?",
				revertButtons: true,
			})

			setOpenWarningModal(true)
		}

		const onStartClick = (id) => {
			setWarningModalProps({
				cancelButtonText: "No",
				submitButtonText: "Yes",
				onSubmit: () => handleWarningModalOnStartClose(id),
				title: "Are you sure what to start the process?",
			})

			setOpenWarningModal(true)
		}

		const onEditClick = (process) => {
			setEditProcessModalProps(process)
			setOpenEditProcessModal(true)
		}

		return columns({
			setSelectedProcessId: dataStore.setSelectedProcessId,
			onStopClick,
			onDeleteClick,
			onStartClick,
			onEditClick,
		})
	}, [])

	const selectedProcessId = dataStore?.selectedProcessId

	const rowStyles = (row) => {
		let styles = {}

		if (row.status === OTAProcessStatus.DISABLED) {
			styles.backgroundColor = "rgba(0, 0, 0, 0.15)" // More transparent background
			// styles.cursor = "not-allowed" // Show disabled cursor
		}

		if (row.id === selectedProcessId) {
			styles.backgroundColor = "rgb(130 202 255 / 40%)"
		}

		return styles
	}

	return (
		<>
			<Table
				onRow={(row) => ({
					onClick: () => handleSetProcessId(row),

					style: rowStyles(row),
				})}
				loading={loading}
				scroll={{ x: true }}
				columns={memoColumns}
				dataSource={processes?.rows}
				rowKey={(item) => item.id}
				onChange={(_a, _b, sorting) =>
					handleOrder({
						order: sorting.order,
						sort: sorting.columnKey === "status" ? "statusPriority" : sorting.columnKey,
					})
				}
				className="process-table"
				pagination={{
					className: "pagination",
					showSizeChanger: false,
					position: ["bottomRight"],
					total: processes?.total,
					pageSize: pageSize,
					current: page + 1,
					onChange: (page, pageSize) => handlePagination(page, pageSize),
				}}
			/>

			<WarningModal {...warningModalProps} isOpen={openWarningModal} onCancel={handleWarningModalClose} />

			{openEditProcessModal && (
				<EditProcessModal
					initialState={editProcessModalProps}
					isOpen={openEditProcessModal}
					onCancel={handleEditProcessModalClose}
				/>
			)}
		</>
	)
})

ProcessTable.propTypes = {}

export default ProcessTable
