import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Checkbox, Divider, Modal } from "antd"

const CheckboxGroup = Checkbox.Group

const ErrorsOnlyModal = ({ isOpen, onCancel, list, onSubmit }) => {
	const [checkedList, setCheckedList] = useState([])

	const checkAll = list.length === checkedList.length
	const indeterminate = checkedList.length > 0 && checkedList.length < list.length

	const onChange = (list) => {
		setCheckedList(list)
	}

	const onCheckAllChange = (e) => {
		setCheckedList(e.target.checked ? list : [])
	}

	return (
		<Modal
			title={"Errors Only"}
			open={isOpen}
			onCancel={onCancel}
			onOk={onSubmit}
			footer={[
				<Button danger type="text" key="close" onClick={onCancel}>
					Close
				</Button>,
				<Button type="primary" key="success" onClick={onSubmit}>
					Save
				</Button>,
			]}
		>
			<div>
				<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
					Check all
				</Checkbox>
				<Divider />

				<CheckboxGroup
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
						alignItems: "flex-start",
						width: "400px",
						gap: "10px",
						flexBasis: "45%",
					}}
					value={checkedList}
					onChange={onChange}
				>
					{list.map((v) => (
						<Checkbox key={v} value={v} style={{ width: "45%" }}>
							{v}
						</Checkbox>
					))}
				</CheckboxGroup>
			</div>
		</Modal>
	)
}

ErrorsOnlyModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	list: PropTypes.array.isRequired,
}

export default ErrorsOnlyModal
