import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { AutoComplete } from "antd"

const VersionsInput = ({ version, setVersion, versionArray, isDisabled = false }) => {
	const [versionNameArray, setVersionNameArray] = useState([])
	const [versionOptions, setVersionOptions] = useState([])
	const [versionName, selectVersionName] = useState(version?.name)

	useEffect(() => {
		if (!versionArray?.length) return

		const newVersionNameArray = versionArray?.map((el) => {
			return { value: el.name, label: el.name }
		})

		setVersionNameArray(newVersionNameArray)
		setVersionOptions(newVersionNameArray)
	}, [versionArray])

	useEffect(() => {
		if (!versionName) return

		const versionData = versionArray.find((el) => el.name === versionName)
		setVersion(versionData)
	}, [versionName])

	return (
		<>
			<AutoComplete
				options={versionOptions}
				style={{ width: "100%" }}
				placeholder="Version Name"
				disabled={isDisabled}
				value={versionName}
				onSelect={(val) => {
					selectVersionName(val)
				}}
				onSearch={(val) => {
					let data = versionNameArray.filter((d) => d.value.vatoLowerCase().includes(val?.toLowerCase()))
					setVersionOptions(data)
				}}
			/>
		</>
	)
}

VersionsInput.propTypes = {
	version: PropTypes.object.isRequired,
	setVersion: PropTypes.func.isRequired,
	versionArray: PropTypes.array.isRequired,
	isDisabled: PropTypes.bool,
}

export default VersionsInput
