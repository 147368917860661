import React from "react"
import * as moment from "moment"
import PropTypes from "prop-types"

const TimeDisplay = ({ date, onlyDate }) => {
	const momentDate = moment(date)

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			{!onlyDate && <span>{momentDate.format("HH:mm:ss")}</span>}
			<span>{momentDate.format("MM.DD.YYYY")}</span>
		</div>
	)
}

TimeDisplay.propTypes = {
	date: PropTypes.func.isRequired,
	onlyDate: PropTypes.bool,
}

export default TimeDisplay
