import React from "react"
import PropTypes from "prop-types"
import { Flex, Input, Typography } from "antd"

const { Title } = Typography

const StatsItem = ({ customStyles, titleStyles, title, value, fullWidth, color, background, popover, children }) => {
	const inputStyles = {
		cursor: "text",
		color: color || "black",
		flex: "1 1 0px",
		height: "35px",
		fontSize: "14px",
		textWrap: "nowrap",
		textAlign: "center",
		...(background && { background }),
		...customStyles,
	}

	const mainTitleStyles = {
		flex: "1 1 0px",
		margin: 0,
		alignSelf: "center",
		textWrap: "nowrap",
		fontSize: "14px",
		...titleStyles
	}

	const baseInput = {
		color: "rgba(0, 0, 0, 0.25)",
		backgroundColor: "rgba(0, 0, 0, 0.04)",
		borderColor: "#d9d9d9",
		boxShadow: "none",
		cursor: "not-allowed",
		opacity: 1,
		padding: "0px 7px",
		borderRadius: "4px",
		width: "calc(100% + 4px)",
		display: "inline-block",
		boxSizing: "border-box",
	}

	return (
		<Flex
			justify="flex-start"
			align="center"
			wrap="wrap"
			style={{
				gap: "1rem",
				flex: "1 1 0px",
				...(!fullWidth
					? {
							minWidth: "48%",
						}
					: {
							width: "100%",
						}),
			}}
		>
			{title && (
				<Title
					level={5}
					style={mainTitleStyles}
				>
					{title}
				</Title>
			)}

			{children ? (
				<div style={{ ...baseInput, ...inputStyles }}>{children}</div>
			) : (
				<>
					{popover ? (
						popover(<Input value={value} disabled size="small" style={inputStyles} />)
					) : (
						<Input value={value} disabled size="small" style={inputStyles} />
					)}
				</>
			)}
		</Flex>
	)
}
StatsItem.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	color: PropTypes.string,
	background: PropTypes.string,
	customStyles: PropTypes.any,
	titleStyles: PropTypes.any,
	popover: PropTypes.any,
	children: PropTypes.any,
}

export default StatsItem
