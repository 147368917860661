import { API } from "../App"
import { makeObservable, observable, action } from "mobx"

class VersionStore {
	versions = []
	versionsFilters = {}
	selectedVersionId = null
	inProgress = false
	error = null

	constructor() {
		makeObservable(this, {
			versions: observable,
			versionsFilters: observable,
			selectedVersionId: observable,

			inProgress: observable,
			error: observable,
		})
	}

	async callAPI(method, data = {}) {
		this.inProgress = true
		this.error = null

		const response = await method(data)
		if (response.ok) {
			this.inProgress = false

			return response.data
		} else {
			this.error = response.data.error
		}

		this.inProgress = false
	}

	async setVersionsFilters(filters) {
		this.versionsFilters = filters
	}

	async getVersions() {
		const response = await this.callAPI(API.getVersions, this.versionsFilters)
		this.versions = response
	}

	async setSelectedVersionId(id) {
		this.selectedVersionId = id
	}
}

export default VersionStore
