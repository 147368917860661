import React from "react"
import { Flex, Spin } from "antd"
import PropTypes from "prop-types"

const Loader = ({ hideSubText }) => (
	<Flex style={{ width: "100%", height: "100%", minHeight: "90px" }} justify="center" align="center">
		<Spin
			// tip='Loading'
			size="large"
		>
			{!hideSubText && <span style={{ position: "absolute", top: "25px", right: "-38px" }}>Loading...</span>}
		</Spin>
	</Flex>
)

Loader.propTypes = {
	hideSubText: PropTypes.bool,
}

export default Loader
