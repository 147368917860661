import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Divider, Flex, Input, Modal, Steps, List, Typography, Alert } from "antd"
import WarningModal from "./WarningModal"
import Title from "../Title"
import { API } from "../../../App"
import { observer } from "mobx-react"
import { useStore } from "../../../stores"

const { TextArea } = Input

const VersionNameForm = ({ setStatedValue, statedValue }) => {
	const [versionName, setVersionName] = useState(statedValue)
	const [error, setError] = useState()

	useEffect(() => {
		if (versionName?.length === 0) {
			setError({
				status: "error",
				placeholder: "Version name field could not be empty",
			})
		} else {
			setError()
		}
	}, [versionName])

	return (
		<Flex gap="middle" style={{ width: "100%", padding: "20px 0" }} vertical>
			<Flex gap="middle" style={{ width: "50%" }} justify={"flex-start"} align="center">
				<Title asterisk title="Version Name" />
				<Input
					{...error}
					value={versionName}
					disabled
					onChange={(v) => {
						setVersionName(v.target.value), setStatedValue(v.target.value)
					}}
				/>
			</Flex>

			{error && <Alert style={{ width: "50%" }} message={error.placeholder} type="error" />}
		</Flex>
	)
}

VersionNameForm.propTypes = {
	statedValue: PropTypes.object.isRequired,
	setStatedValue: PropTypes.func.isRequired,
}

const EditVersionModal = observer(({ isOpen, onCancel, initialState }) => {
	const { name: initialName, comments: initialComments } = initialState
	const { versionStore } = useStore()

	const [isWarningModalOpen, setIsWarningModalOpen] = useState()

	const [versionName, setVersionName] = useState(initialName)
	const [comments, setComments] = useState(initialComments)

	const handleWarningModalClose = () => setIsWarningModalOpen(false)

	const [loading, setLoading] = useState(false)

	// Finish step
	const onWarningModalSubmit = async () => {
		setLoading(true)

		try {
			const res = await API.updateVersion(initialState.id, {
				comments,
				// versionName,
				// firmwareData,
				// databaseData,
			})

			setLoading(false)

			if (!res.ok) {
				handleWarningModalClose()
				setError({ status: "error", placeholder: res.data.error.message })
				openNotificationWithIcon("error", res.data.error.message)

				return
			}
		} catch (err) {
			setLoading(false)
			return
		}

		setLoading(false)

		setIsWarningModalOpen(false)

		onCancel()
	}

	const modalStyles = {
		content: {
			width: "80vw",
			maxWidth: "900px",
		},
	}

	const isCreateButtonDisabled = initialComments === comments

	return (
		<Modal title={"Version creation"} open={isOpen} onCancel={onCancel} footer={[]} styles={modalStyles}>
			<Flex gap={"middle"} vertical justify="flex-start" align="flex-start">
				<VersionNameForm setStatedValue={setVersionName} statedValue={versionName} />

				<Flex gap="middle" style={{ width: "80%" }} justify={"flex-start"} align="start">
					<Title title="Comments" />
					<TextArea
						rows={4}
						placeholder="Comments..."
						style={{ resize: "none" }}
						value={comments}
						onChange={(e) => setComments(e.target.value)}
					/>
				</Flex>

				<WarningModal
					{...{
						cancelButtonText: "No",
						submitButtonText: "Yes",
						onSubmit: onWarningModalSubmit,
						title: `Do you want to update the current version?`,
					}}
					loading={loading}
					isOpen={isWarningModalOpen}
					onCancel={handleWarningModalClose}
				/>

				<Flex gap="middle" style={{ width: "100%", paddingTop: "3rem" }} justify={"flex-end"} align="center">
					<Button onClick={onCancel} type="primary" danger>
						Cancel
					</Button>
					<Button onClick={() => setIsWarningModalOpen(true)} disabled={isCreateButtonDisabled} type="primary">
						Update
					</Button>
				</Flex>
			</Flex>
		</Modal>
	)
})

EditVersionModal.propTypes = {
	isOpen: PropTypes.bool,
	initialState: PropTypes.object.isRequired,
	onCancel: PropTypes.func,
}

export default EditVersionModal
