import { Card, Flex, Typography, Tabs } from "antd"
import React, { useEffect, useState } from "react"

import {
	ScreenLayout,
	OTAScreenHeader,
	ProcessTable,
	VersionsTable,
	DetailsTabs,
	VersionDetailsTabs,
} from "../components"
import { observer } from "mobx-react"
import { useStore } from "../stores"

const { TabPane } = Tabs

const OTAScreen = observer(() => {
	const { dataStore, versionStore } = useStore()
	const [tab, setTab] = useState()

	return (
		<ScreenLayout>
			<Card
				title={<Typography.Title level={4}>OTA Update Management</Typography.Title>}
				style={{
					width: "85vw",
					maxWidth: "1200px",
					margin: "auto",
					marginTop: 40,
					fontSize: "large",
				}}
			>
				<Flex vertical wrap="wrap" justify="space-end" align="flex-end">
					<OTAScreenHeader />
					<Flex
						gap="middle"
						justify="space-between"
						align="flex-start"
						wrap="wrap"
						style={{ width: "100%", marginBottom: "24px" }}
					>
						<Tabs onChange={setTab} defaultActiveKey="processes" type="card" size="large" style={{ width: "100%" }}>
							<TabPane key="processes" tab="Processes">
								<>
									<ProcessTable />

									{dataStore.selectedProcessId && <DetailsTabs selectedProcessId={dataStore.selectedProcessId} />}
								</>
							</TabPane>

							<TabPane key="versions" tab="Versions">
								<>
									<VersionsTable />

									{versionStore.selectedVersionId && (
										<VersionDetailsTabs selectedVersionId={versionStore.selectedVersionId} />
									)}
								</>
							</TabPane>
						</Tabs>
					</Flex>
				</Flex>
			</Card>
		</ScreenLayout>
	)
})

export default OTAScreen
