import React, { useState } from "react"
import { Flex, Tabs } from "antd"
import VersionDetails from "./components/VersionDetails"
import VersionProcessesTable from "./components/VersionProcessesTable"
import PropTypes from "prop-types"

const { TabPane } = Tabs

const VersionDetailsTabs = ({ selectedVersionId }) => {
	const [tab, setTab] = useState(null)

	return (
		<Flex
			vertical
			justify="flex-start"
			align="flex-start"
			gap={"large"}
			style={{ width: "100%", minHeight: "400px", marginTop: "-25px" }}
		>
			<Tabs onChange={setTab} defaultActiveKey="details" type="card" size="large" style={{ width: "100%" }}>
				<TabPane key="details" tab="Details">
					<VersionDetails selectedVersionId={selectedVersionId} />
				</TabPane>

				<TabPane key="processes" tab="Processes">
					<VersionProcessesTable versionId={selectedVersionId} />
				</TabPane>
			</Tabs>
		</Flex>
	)
}

VersionDetailsTabs.propTypes = {
	selectedVersionId: PropTypes.string.isRequired,
}

export default VersionDetailsTabs
