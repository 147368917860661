import React, { useEffect, useMemo, useState } from "react"
import { Space, Table, Typography } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import TooltipIconButton from "../shared/TooltipIconButton"
import { WarningModal } from "../shared/modals"
import { API } from "../../App"
import { useOrder } from "../shared/hooks/UseOrder"
import { observer } from "mobx-react"
import { useStore } from "../../stores"
import moment from "moment"
import { rearrangeName } from "./OTAScreenHeader"

const columns = ({ onDeleteClick }) => {
	return [
		{
			title: "Version name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Firmware Version",
			dataIndex: "firmwareVersion",
			key: "firmwareVersion",
		},
		{
			title: "DB Version",
			dataIndex: "databaseVersion",
			key: "databaseVersion",
		},
		{
			title: "Date Created",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: true,
			render: (_, { createdAt }) => moment(createdAt).format("MM.DD.YYYY HH:mm"),
		},

		{
			title: "Created By",
			dataIndex: "createdBy",
			key: "createdBy",
			minWidth: "200px",
			render: (_, { createdBy }) => rearrangeName(createdBy || ""),
		},

		{
			title: "Action",
			key: "action",
			render: (_, row) => {
				return (
					<Space size="middle">
						<TooltipIconButton
							onClick={() => onDeleteClick(row.id)}
							icon={<DeleteOutlined color="red" />}
							text="Delete"
						/>
					</Space>
				)
			},
		},
	]
}

const VersionsTable = observer(() => {
	const { versionStore } = useStore()

	const [openWarningModal, setOpenWarningModal] = useState()
	const [warningModalProps, setWarningModalProps] = useState()

	//Rename data for exact OTA
	const [page, setPage] = useState(0)
	const [order, handleOrder] = useOrder()
	const [loading, setLoading] = useState()
	const [pageSize, setPageSize] = useState(5)
	const versions = useMemo(() => {
		if (versionStore?.versions?.rows) {
			if (versionStore?.versions?.rows?.length > 0)
				versionStore.setSelectedVersionId(versionStore?.versions?.rows[0].id)
		}

		return versionStore.versions
	}, [versionStore.versions])

	const handlePagination = (page, pageSize) => {
		setPage(page - 1)
		setPageSize(pageSize)
	}

	const handleSetVersionId = (id) => versionStore.setSelectedVersionId(id)

	const fetchVersions = async (page, limit, order) => {
		setLoading(true)
		try {
			versionStore.setVersionsFilters({ offset: page * limit, limit, ...order })

			await versionStore.getVersions()

			if (versionStore?.versions?.rows) {
				if (versionStore?.versions?.rows?.length > 0)
					versionStore.setSelectedVersionId(versionStore?.versions?.rows[0].id)
			}
		} catch (err) {
			console.log("err", err)
		}

		setLoading(false)
	}

	useEffect(() => {
		fetchVersions(page, pageSize, order)
	}, [page, pageSize, order.sort, order.order])

	const handleWarningModalClose = async () => {
		setOpenWarningModal()
	}

	const handleWarningModalOnDeleteClose = async (id) => {
		setOpenWarningModal()
		await API.deleteVersion(id)
		await versionStore.getVersions()
		await dataStore.getProcesses()
	}

	const memoColumns = useMemo(() => {
		const onDeleteClick = (id) => {
			setWarningModalProps({
				cancelButtonText: "No",
				submitButtonText: "Yes",
				onSubmit: () => handleWarningModalOnDeleteClose(id),
				title: (
					<Typography.Text style={{ textAlign: 'center', display: 'block' }}>
						Are sure what to delete the version?
						<br />
						All related process will be stopped and disabled.
						<br />
						<strong>You won&apos;t be able to revert this action.</strong>
					</Typography.Text>
				),				
				revertButtons: true,
			})

			setOpenWarningModal(true)
		}

		return columns({
			onDeleteClick,
		})
	}, [])

	const selectedVersionId = versionStore?.selectedVersionId

	return (
		<>
			<Table
				onRow={(row) => ({
					onClick: () => handleSetVersionId(row.id),

					style:
						row.id === selectedVersionId
							? {
									backgroundColor: "rgb(130 202 255 / 40%)",
								}
							: {},
				})}
				loading={loading}
				scroll={{ x: true }}
				columns={memoColumns}
				dataSource={versions?.rows}
				rowKey={(item) => item.id}
				onChange={(_a, _b, sorting) =>
					handleOrder({
						order: sorting.order,
						sort: sorting.columnKey === "status" ? "statusPriority" : sorting.columnKey,
					})
				}
				className="version-table"
				pagination={{
					className: "pagination",
					showSizeChanger: false,
					position: ["bottomRight"],
					total: versions?.total,
					pageSize: pageSize,
					current: page + 1,
					onChange: (page, pageSize) => handlePagination(page, pageSize),
				}}
			/>

			<WarningModal {...warningModalProps} isOpen={openWarningModal} onCancel={handleWarningModalClose} />
		</>
	)
})

VersionsTable.propTypes = {}

export default VersionsTable
