import { useState } from "react"

export const useOrder = (defaultSort) => {
	const [sort, setSort] = useState(defaultSort || "createdAt")
	const [order, setOrder] = useState("DESC")

	const handleOrder = ({ sort, order }) => {
		console.log({ sort, order })

		if (!order || ["descending", "descend", "desc", "Desc", "DESC"].includes(order)) {
			setOrder("DESC")
		}

		if (["ascending", "ascend", "asc", "Asc", "ASC"].includes(order)) {
			setOrder("ASC")
		}

		if (sort) setSort(sort)
	}

	return [{ sort, order }, handleOrder]
}
