import { createContext, useContext } from "react"
import DataStore from "./DataStore"
import AuthStore from "./AuthStore"
import ProcessDetailsStore from "./ProcessDetailsStore"
import VersionStore from "./VersionStore"
import VersionDetailsStore from "./VersionDetailsStore"

export const store = {
	dataStore: new DataStore(),
	versionStore: new VersionStore(),
	versionDetailsStore: new VersionDetailsStore(),
	authStore: new AuthStore(),
	processDetailsStore: new ProcessDetailsStore(),
}

export const StoreContext = createContext(store)

export const useStore = () => {
	return useContext(StoreContext)
}
